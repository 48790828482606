<template>
  <span>
    <v-select
      v-if="userClusters.length && withClusters"
      v-model="selectedClusters"
      :items="userClusters"
      :label="$lang('main.titles.user_property_clusters')"
      prepend-icon="mdi-home-group"
      item-value="id"
      multiple
      :dense="dense"
      :dark="dark"
      :clearable="clearable"
      return-object
      :menu-props="{
        closeOnContentClick: true,
        closeOnClick: true
      }"
      @input="selectProperties"
    >
      <template v-slot:item="{item}">
        <v-chip small label :color="item.color" class="white--text">
          <v-icon class="mr-1" small>{{item.icon}}</v-icon>
          {{item.name}}
        </v-chip>
      </template>
      <template v-slot:selection="{item}">
        <v-chip small label :color="item.color" class="white--text ma-1">
          <v-icon class="mr-1" small>{{item.icon}}</v-icon>
          {{item.name}}
        </v-chip>
      </template>
    </v-select>
    <v-autocomplete
      v-model="ids"
      :items="properties"
      :label="label"
      :multiple="multiple"
      :small-chips="smallChips"
      :deletable-chips="deletableChips"
      :hint="hint"
      :persistent-hint="persistentHint"
      :loading="loading"
      :prepend-icon="prependIcon"
      append-outer-icon="mdi-refresh"
      :clearable="clearable"
      :disabled="disabled"
      :readonly="readonly"
      :dark="dark"
      @change="change"
      @blur="blur"
      @click:append-outer="getProperties()"
      :dense="dense"
      :error="error"
      :error-messages="!!errorMessages ? errorMessages : []"
    >
      <template v-if="withSelectionButtons" v-slot:prepend-item>
        <v-list-item class="primary">
            <v-list-item-content>
              <v-row class="ma-1">
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="selectAllProperties" small>{{ $lang('main.settings.select_all') }}</v-btn>
                </v-col>
                <v-col cols="6" align="center" class="ma-0 pa-0">
                  <v-btn @click="deselectAllProperties" small>{{ $lang('main.settings.deselect_all') }}</v-btn>
                </v-col>
              </v-row>
          </v-list-item-content>
        </v-list-item>
        <v-divider class="mt-1"></v-divider>
      </template>
    </v-autocomplete>
  </span>
</template>

<script>
import { mapState } from "vuex";
import { GET_PROPERTIES_LIST } from "@/store/properties/actions";

export default {
	name: "CPropertiesAutocomplete",
	props: {
		label: {
			type: String,
			required: false,
      default: 'Properties'
		},
		hint: {
			type: String,
			default: ""
		},
    persistentHint: {
      type: Boolean,
      default: false
    },
		value: {
			default: null
		},
		error: {
			default: false
		},
		errorMessages: {
			default: () => []
		},
		dense: {
			type: Boolean,
			default: false
		},
		prependIcon: {
			default: "mdi-office-building"
		},
		clearable: {
			type: Boolean,
			default: false
		},
    multiple: {
      type: Boolean,
			default: false
    },
		disabled: {
			type: Boolean,
			default: false
		},
		readonly: {
			type: Boolean,
			default: false
		},
		dark: {
			type: Boolean,
			default: false
		},
    smallChips: {
      type: Boolean,
			default: false
    },
    deletableChips: {
      type: Boolean,
			default: false
    },
    withClusters: {
      type: Boolean,
      default: false
    },
    withSelectionButtons: {
      type: Boolean,
      default: false
    },
    options: {
      type: Object,
      default: () => {
        return {};
      }
    }
	},
	data() {
		return {
			ids: null,
      selectedClusters: [],
      selectedIds: [],
      cache: false
		};
	},
  beforeMount() {
    if (!this.isListCached || Object.keys(this.options).length) {
      this.getProperties();
      this.cache = true;
    }
  },
  mounted() {
    this.ids = this.value;
  },
	methods: {
    getProperties() {
      let filters = {};
      if (!!this.options.companyId) {
        filters.company_id = this.options.companyId;
      }

      if (!!this.options.withoutOwner) {
        filters.without_owner = true
      }

      this.$store.dispatch(GET_PROPERTIES_LIST, {
        sortBy: 'name',
        order: 'asc',
        ...filters,
      });
    },
		change() {
			this.$emit("change");
		},
    blur() {
      this.$emit("blur");
    },
    selectProperties() {
      this.ids = [];

      this.selectedClusters.forEach(cluster => {
        cluster.propertyIds.forEach(id => {
          this.ids.push(id);
        })
      });
    },
    selectAllProperties() {
      let items = [];

      Object.keys(this.properties).map(key => {
        items.push(this.properties[key].value);
      });
      
      this.ids = [...items];
    },
    deselectAllProperties() {
      this.ids = [];
    },
	},
  computed: {
    ...mapState({
      propertiesRequest: state => state.properties.getListRequest,
    }),
    properties() {
      if (!this.propertiesRequest.list) {
        return [];
      }

      return this.propertiesRequest.list;
    },
    loading() {
      return this.propertiesRequest.loading;
    },
    isListCached() {
      return this.propertiesRequest.success;
    },
    userClusters() {
      if (!this.userData.clusters && !this.userData.clusters.length) {
        return [];
      }

      let clusters = [];

      this.userData.clusters.forEach(cluster => {
        let propertyIds = [];

        cluster.properties.forEach(property => {
          propertyIds.push(property.id);
        });

        clusters.push({
          id: cluster.id,
          name: cluster.name,
          icon: cluster.icon,
          color: cluster.color,
          propertyIds: propertyIds
        });
      });

      return clusters;
    },
  },
  watch: {
    ids(newVal) {
      this.$emit('input', newVal);
    },
    value(newVal) {
      this.ids = newVal;
    }
  }
};
</script>
