import moment from "moment/moment";

/**
 * @type {number}
 */
const full = 1;
const byUnit = 2;
const byPropertyUnit = 3;

/**
 * @returns {string}
 */
const now = () => {
  return moment().format('YYYY-MM-DD');
};

/**
 * @type {number}
 */
const days = 31;//moment().daysInMonth();

export default {
  date: now(),
  days: days,
  view: byUnit,
  with_reservations: true,
  with_rates: false,
}
