<template>
  <v-menu
    ref="displayClock"
    v-model="displayClock"
    :close-on-content-click="false"
    :nudge-right="nudge"
    :return-value.sync="time"
    transition="scale-transition"
    offset-y
    :max-width="maxWidth"
    :min-width="minWidth"
  >
    <template v-slot:activator="{ on }">
      <v-text-field
        v-model="time"
        :disabled="disabled"
        :label="label"
        :hint="hint"
        :persistent-hint="persistentHint"
        prepend-icon="mdi-clock-outline"
        readonly
        :error="error"
        :error-messages="errorMessages"
        v-on="on"
      ></v-text-field>
    </template>
    <v-time-picker
      v-if="displayClock"
      v-model="time"
      :disabled="disabled"
      @click:minute="$refs.displayClock.save(time)"
      :format="timeFormat"
      :allowed-minutes="allowedMinutes"
    ></v-time-picker>
  </v-menu>
</template>

<script>
  export default {
    name: "VClock",
    props: {
      value: {
        default: null
      },
      disabled:{
        type: Boolean,
        default: false
      },
      label: {
        type: String,
        default: 'Clock'
      },
      nudge: {
        type: Number,
        default: 40
      },
      maxWidth: {
        type: String,
        default: '290px',
      },
      minWidth: {
        type: String,
        default: '290px',
      },
      error:{
        default: false
      },
      errorMessages: {
        default: () => []
      },
      timeFormat: {
        type: String,
        default: '24hr'
      },
      allowedMinutes: {
        default: null,
      },
      hint: {
        type: String,
        default: null
      },
      persistentHint: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        displayClock: false,
        time: null
      }
    },
    created() {
      this.time = this.value;
    },
    watch: {
      value(value) {
        this.time = value;
      },
      time(newVal) {
        this.$emit('input', newVal)
      }
    }
  }
</script>
