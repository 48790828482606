<template>
  <div class="d-flex">
    <v-menu
      v-model="menu"
      :close-on-content-click="false"
      :nudge-right="nudge"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          :value="dateValueFormatted"
          :label="label"
          :hint="hint"
          persistent-hint
          readonly
          v-on="on"
          :error="error"
          :error-messages="errorMessages"
          :dense="dense"
          :hide-details="hideDetails"
          :clearable="clearable"
          :disabled="disabled"
          :dark="dark"
          :prepend-icon="prependIconCalculated"
          :append-outer-icon="!!withPlus ? 'mdi-plus' : ''"
          @click:append-outer="addDay()"
          @click:prepend="subDay()"
        ></v-text-field>
      </template>
      <v-date-picker
        v-model="dateValue"
        @input="closeDatePicker"
        @change="changed"
        :locale="lang"
        :min="minValue"
        :max="maxValue"
        :scrollable="scrollable"
        :type="type"
        :first-day-of-week="firstDayOfWeek"
        :readonly="readonly"
        :no-title="withNoTitle"
        :header-date-format="handleHeaderDateFormat"
      >
        <template v-slot:default v-if="withToday">
          <v-btn color="primary" @click="setToday()">{{ $lang('main.words.today') }}</v-btn>
        </template>
      </v-date-picker>
    </v-menu>
    <v-menu
      v-model="timeMenu"
      :close-on-content-click="false"
      :nudge-right="nudge"
      transition="scale-transition"
      offset-y
      min-width="290px"
    >
      <template v-slot:activator="{ on }">
        <v-text-field
          v-model="timeValueFormatted"
          readonly
          v-on="on"
          :error="error"
          :error-messages="errorMessages"
          :dense="dense"
          :hide-details="hideDetails"
          :clearable="clearable"
          :disabled="disabled"
          :dark="dark"
          prepend-icon="mdi-clock"
          type="time"
        ></v-text-field>
      </template>
      <v-time-picker
        v-model="timeValue"
        :format="timeFormat"
      >
        <template v-slot:default>
          <v-row>
            <v-col cols="6">
              <v-btn block @click="timeMenu = false">{{ $lang('main.crud.cancel') }}</v-btn>
            </v-col>
            <v-col cols="6">
              <v-btn block color="primary" @click="changed()">{{ $lang('main.crud.ok') }}</v-btn>
            </v-col>
          </v-row>
        </template>
      </v-time-picker>
    </v-menu>
  </div>
</template>

<script>
  export default {
    name: "VDate",
    props: {
      label: {
        type: String,
        required: false,
      },
      hint: {
        type: String,
        default: ''
      },
      nudge: {
        type: Number,
        default: 40
      },
      value: {
        default: null
      },
      error: {
        default: false
      },
      errorMessages: {
        default: () => []
      },
      dense: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      prependIcon: {
        default: 'mdi-calendar'
      },
      locale: {
        type: String
      },
      min: {
        type: String,
        required: false,
        default: null
      },
      max: {
        type: String,
        required: false,
        default: null
      },
      scrollable: {
        type: Boolean,
        default: true,
      },
      type: {
        type: String,
        default: 'date',
      },
      firstDayOfWeek: {
        type: Number,
        default: 1
      },
      clearable: {
        type: Boolean,
        default: false,
      },
      disabled: {
        type: Boolean,
        default: false,
      },
      readonly: {
        type: Boolean,
        default: false,
      },
      dark: {
        type: Boolean,
        default: false,
      },
      withPlus: {
        type: Boolean,
        default: false,
      },
      withMinus: {
        type: Boolean,
        default: false,
      },
      withToday: {
        type: Boolean,
        default: true,
      },
      withNoTitle: {
        type: Boolean,
        default: false,
      },
      isMonthDay: {
        type: Boolean,
        default: false,
      },
      headerDateFormat: {
        type: String,
        default: 'MMMM YYYY',
      },
      timeFormat: {
        type: String,
        default: '24hr'
      }
    },
    data() {
      return {
        dateTimeValue: null,
        dateValue: null,
        timeValue: null,
        menu: false,
        timeMenu: false,
        minValue: null,
        maxValue: null,
        lang: null,
      }
    },
    mounted() {
      this.lang = this.locale ? this.locale : this.$locale();
    },
    created() {
      this.dateTimeValue = this.value;
      this.minValue = this.min;
      this.maxValue = this.max;
      this.setValues();
    },
    methods: {
      closeDatePicker() {
        this.menu = false;
      },
      setValues() {
        if (
          !this.value ||
          (this.dateValue || this.timeValue)
        ) {
          return;
        }

        this.dateValue = this.$moment(this.value).format('YYYY-MM-DD');
        this.timeValue = this.$toDateTimeFormat(this.value, 'HH:mm:ss');
      },
      setDateTimeValue() {
        if (!this.dateValue || !this.timeValue) {
          return;
        }

        this.dateTimeValue = this.$moment(`${this.dateValue} ${this.timeValue}`).format('YYYY-MM-DD HH:mm');
      },
      setToday() {
        this.dateValue = this.$moment().format('YYYY-MM-DD');
        this.menu = false;
        this.$emit('change');
      },
      changed() {
        this.timeMenu = false;
        this.setDateTimeValue();
        this.$emit('change');
      },
      addDay() {
        if (!this.withPlus || !this.dateValue) return;
        this.dateValue = this.$moment(this.dateValue).add(1, 'day').format('YYYY-MM-DD');
      },
      subDay() {
        if (!this.withMinus || !this.dateValue) return;
        this.dateValue = this.$moment(this.dateValue).subtract(1, 'day').format('YYYY-MM-DD');
      },
      handleHeaderDateFormat(date) {
        return this.$moment(date).locale(this.lang).format(this.headerDateFormat);
      }
    },
    watch: {
      value() {
        this.dateTimeValue = this.value;
        this.setValues();
      },
      min() {
        this.minValue = this.min;
      },
      dateTimeValue(newVal) {
        this.$emit('input', newVal);
      },
    },
    computed: {
      prependIconCalculated() {
        if (this.withMinus) {
          return 'mdi-minus';
        }

        if (!!this.prependIcon) {
          return this.prependIcon;
        }

        return '';
      },
      timeValueFormatted() {
        if (!this.dateTimeValue) {
          return;
        }

        return this.timeValue;
      },
      dateValueFormatted() {
        if (!this.dateValue) {
          return;
        }

        return this.$toDateFormat(this.dateValue);
      }
    }
  }
</script>
