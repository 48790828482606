<template>
  <div>
      <v-autocomplete
        v-model="ids"
        :items="sources.collection"
        :loading="sources.loading"
        :label="label ? label : $lang('reservations.filters.source')"
        item-value="id"
        item-text="human_name"
        :clearable="clearable"
        :single-line="singleLine"
        :hide-details="hideDetails"
        :multiple="multiple"
        :error="!!errors.length"
        :error-messages="errors"
      >
        <template v-slot:item="{item}">
          <v-chip
            class="ml-0 mb-2"
            color="rgba(0,0,0,0.4)"
            pill
            style="overflow: hidden; color: #fff;"
          >
            <v-avatar left>
              <v-img :src="item.image_url"></v-img>
            </v-avatar>
            {{ item.human_name }}
          </v-chip>
        </template>
        <template v-if="!defaultSelection" v-slot:selection="{item}">
          <v-chip
            class="ml-0 mb-2"
            color="rgba(0,0,0,0.4)"
            pill
            style="overflow: hidden; color: #fff;"
            small
          >
            <v-avatar left>
              <v-img :src="item.image_url"></v-img>
            </v-avatar>
            {{ item.human_name }}
          </v-chip>
        </template>
      </v-autocomplete>
  </div>
</template>

<script>
  import { mapState } from "vuex";

  import {
    GET_RESERVATION_SOURCES_COLLECTION
  } from "@/store/reservation-sources/actions";

  export default {
    name: "CReservationSources",
    props: {
      value: {
        default: null
      },
      label: {
        type: String,
        default: null
      },
      defaultSelection: {
        type: Boolean,
        default: true,
      },
      clearable: {
        type: Boolean,
        default: false
      },
      singleLine: {
        type: Boolean,
        default: false
      },
      hideDetails: {
        type: Boolean,
        default: false
      },
      multiple: {
        type: Boolean,
        default: false
      },
      errors: {
        type: Array,
        required: false,
        default: () => {
          return [];
        }
      }
    },
    data() {
      return {
        ids: [],
      }
    },
    beforeMount() {
      this.$store.dispatch(GET_RESERVATION_SOURCES_COLLECTION);
    },
    created() {
      this.ids = this.value;
    },
    watch: {
      value(newVal) {
        this.ids = newVal;
      },
      ids: {
        handler(newVal) {
          this.$emit('input', newVal);
        },
        deep: true
      }
    },
    computed: {
      ...mapState({
        sources: state => state.reservationSources.getCollectionRequest,
      })
    }
  }
</script>
