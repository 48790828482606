<template>
  <v-card :flat="flat" :outlined="outlined">
    <v-card-text class="pa-0">
      <v-expansion-panels :flat="flat" v-model="isExpanded">
        <v-expansion-panel>
          <v-expansion-panel-header v-if="showHeader" :disable-icon-rotate="disableIconRotate" class="mb-0 pb-0 pt-1">
            <template v-slot:actions>
              <v-icon v-if="isExpanded === 0" color="accent">{{ expandIcon }}</v-icon>
              <v-icon v-else>{{ expandIcon }}</v-icon>
            </template>
            <template v-slot:default>
              <slot name="header">
                <h3>{{ $lang('main.words.filters') }}</h3>
              </slot>
            </template>
          </v-expansion-panel-header>
          <v-expansion-panel-content :class="!showHeader ? 'pt-2 mt-2' : null">
            <slot name="content"></slot>
          </v-expansion-panel-content>
        </v-expansion-panel>
      </v-expansion-panels>
      <v-btn v-if="withExtra" icon @click="showExtra = !showExtra" class="mx-4 px-2">
        <v-icon v-if="!showExtra">mdi-chevron-down</v-icon>
        <v-icon v-if="showExtra">mdi-chevron-up</v-icon>
      </v-btn>
      <v-slide-y-transition>
        <v-container class="ma-0" v-if="showExtra">
          <v-row class="px-2 mx-2">
            <v-col cols="12" xs="12" md="12">
                <slot name="extra"></slot>
            </v-col>
          </v-row>
        </v-container>
      </v-slide-y-transition>
    </v-card-text>
  </v-card>
</template>

<script>
  export default {
    name: "CCrudFilters",
    props: {
      value: {
        default: null
      },
      options: {
        type: Object,
        required: false,
        default() {
          return {};
        }
      },
      flat: {
        type: Boolean,
        default: true,
        required: false
      },
      isMobile: {
        type: Boolean,
        default: false,
        required: false,
      },
      outlined: {
        type: Boolean,
        default: true,
        required: false
      },
      expandIcon: {
        type: String,
        default: 'mdi-filter',
        required: false
      },
      disableIconRotate: {
        type: Boolean,
        default: true,
      },
      withExtra: {
        type: Boolean,
        default: false,
      }
    },
    data() {
      return {
        isExpanded: null,
        showHeader: true,
        showExtra: false,
      }
    },
    created() {
      this.isExpanded = !this.isMobile ? 0 : null;
      this.showHeader = this.isMobile;
    },
  }
</script>