<template>
  <div :id="tour" class="form-header">
    <v-toolbar v-if="fullscreen" dark :color="color">
      <template>
        <slot name="left-buttons">
        </slot>
        <v-btn v-if="withClose" icon @click="$emit('cancel')">
          <v-icon>mdi-close</v-icon>
        </v-btn>
        <!-- <v-btn v-if="!!tour && $can('VIEW_TOURS') && !$isMobile()" id="start-tour-btn" icon dark @click="startTour(tour)">
          <v-icon>mdi-school</v-icon>
        </v-btn> -->
      </template>
      <v-toolbar-title>
        <slot name="title">
          <v-chip v-if="$role('ADMIN') && !!id" color="admin" class="text--primary" small>
            <v-icon small>mdi-identifier</v-icon>
            {{ id }}
          </v-chip>
          {{ title }}
        </slot>
      </v-toolbar-title>
      <v-spacer></v-spacer>
      <v-toolbar-items>
        <slot name="toolbar-items" />
      </v-toolbar-items>
    </v-toolbar>
    <v-card-title v-if="!fullscreen">
      <template>
        <slot name="left-buttons">
          <v-btn v-if="withClose" icon @click="$emit('cancel')">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </slot>
        <!-- <v-btn v-if="!!tour && $can('VIEW_TOURS')" id="start-tour-btn" icon @click="startTour(tour)" class="mr-1" color="blue">
          <v-icon>mdi-school</v-icon>
        </v-btn> -->
      </template>
      <slot name="title">
        {{ title }}
      </slot>
      <v-spacer></v-spacer>
      <slot name="right-buttons" />
    </v-card-title>
  </div>
</template>

<script>
export default {
  name: "FormHeader",
  props: {
    tour: {
      type: String,
      required: false,
      default: null
    },
    title: {
      type: String,
      default: null
    },
    fullscreen: {
      type: Boolean,
      default: true
    },
    color: {
      type: String,
      default: 'primary'
    },
    form: {
      type: Object,
      default() {
        return {}
      }
    },
    withClose: {
      type: Boolean,
      default: false,
    }
  },
  computed: {
    id() {
      if (!this.form.id) {
        return null;
      }

      return this.form.id;
    }
  }
};
</script>
